:root {
  --base-colour: #fafbff;
  --input-colour: #f9fafa;
  --input-border-colour: #d9d9d9;
  --main-font-colour: #0c0f5a;
  --dark-font-colour: #06072d;
  --faded-font-colour: #d2d3f9;
  --main-accent-colour: #8389f6;
  --secondary-accent-colour: #ff336d;
  --transparent-base: #00020ff2;
  --primary-button: #0086e6;
  --secondary-button: #464c6d;
  --chip-colour: #6d6e8d;
  --border-radius: 20px;
}

:root.dark {
  --base-colour: #4d5480;
  --main-font-colour: #dfe1ec;
}

/*# sourceMappingURL=app.ebe9b7a0.css.map */
