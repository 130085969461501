:root {
	--base-colour: hsl(232, 96%, 99%);
	--input-colour: hsl(226, 10%, 98%);
	--input-border-colour: hsl(0, 0%, 85%);
	--main-font-colour: hsl(238, 76%, 20%);
	--dark-font-colour: hsl(238, 76%, 10%);
	--faded-font-colour: hsl(238, 76%, 90%);
	--main-accent-colour: hsl(237, 87%, 74%);
	--secondary-accent-colour: hsl(343, 100%, 60%);
	--transparent-base: hsl(232, 96%, 3%, 0.95);
	--primary-button: hsl(205, 100%, 45%);
	--secondary-button: hsl(230, 22%, 35%);
	--chip-colour: hsl(237, 13%, 49%);

	--border-radius: 20px;
}

:root.dark {
	--base-colour: hsl(231, 25%, 40%);
	--main-font-colour: hsl(231, 25%, 90%);
}
